<template>
    <b-col cols="12" class="mt-1 mb-3">
        <h4 class="clr-dark-grey my-3">SUIVI DES CAPTEURS</h4>
        <b-row align-v="stretch">
            <!-- SENSORS ANALYTICS -->
            <b-col cols="12" md="6">
                <b-row align-v="stretch">
                    <b-col cols="12" md="6" class="mb-2">
                        <div class="basic-card p-0 add-cursor" id="chevron-link-1"
                             @click="goToSensorList('pollen', 'prod', 1)">
                            <div class="content-cards-hover p-3 card-body">
                                <div class="d-flex flex-row-reverse justify-content-between align-items-center">
                                    <div
                                        class="bg-orange rounded shadow icon-square d-flex justify-content-center align-items-center">
                                        <b-icon icon="wifi" class="clr-white" scale="1.4"></b-icon>
                                    </div>
                                    <div class="numbers">
                                        <p class="mb-0 clr-light-grey font-weight-bold">
                                            Capteurs connectés
                                        </p>
                                        <h5 class="mb-0 font-weight-bolder clr-dark-grey">
                                    <span class="font-weight-bolder"
                                          :class="colorLevel(getSensorAnalytics.totalConnectedPollen, getSensorAnalytics.totalPollenCount)">
                                        {{
                                            getSensorAnalytics.totalConnectedPollen
                                        }} / {{ getSensorAnalytics.totalPollenCount }}
                                    </span>
                                            ({{
                                                parseInt(getSensorAnalytics.totalConnectedPollen) / parseInt(getSensorAnalytics.totalPollenCount) | toPourcentage
                                            }})
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="12" md="6" class="mb-2">
                        <div class="basic-card p-0 add-cursor"
                             @click="goToSensorList('pollen', 'stock', 1)">
                            <div class="content-cards-hover p-3 card-body">
                                <div
                                    class="d-flex flex-row-reverse justify-content-between align-items-center align-content-center">
                                    <div
                                        class="bg-orange rounded shadow icon-square d-flex justify-content-center align-items-center">
                                        <b-icon icon="box" class="clr-white" scale="1.4"></b-icon>
                                    </div>
                                    <div class="numbers">
                                        <p class="mb-0 clr-light-grey font-weight-bold">
                                            Capteur en stock
                                        </p>
                                        <h5 class="mb-0 font-weight-bolder clr-dark-grey">
                                            {{
                                                getAnalyticsByTypeAndStatus({
                                                    type: 'pollen',
                                                    status: 'stock',
                                                    statusStep: 1
                                                })
                                            }}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="12" md="6" class="mb-md-0 mb-2">
                        <div class="basic-card p-0 add-cursor"
                             @click="goToSensorList('pollen', 'test', 1)">
                            <div class="content-cards-hover p-3 card-body">
                                <div
                                    class="d-flex flex-row-reverse justify-content-between align-items-center align-content-center">
                                    <div
                                        class="bg-orange rounded shadow icon-square d-flex justify-content-center align-items-center">
                                        <b-icon icon="ui-checks" class="clr-white" scale="1.4"></b-icon>
                                    </div>
                                    <div>
                                        <p class="mb-0 clr-light-grey font-weight-bold">
                                            Capteurs en Test
                                        </p>
                                        <h5 class="mb-0 font-weight-bolder clr-dark-grey">
                                            {{
                                                getAnalyticsByTypeAndStatus({
                                                    type: 'pollen',
                                                    status: 'test',
                                                    statusStep: 1
                                                })
                                            }}
                                            <span class="font-weight-bolder text-success"> </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="12" md="6" class="mb-md-0 mb-2">
                        <div class="basic-card p-0 add-cursor"
                             @click="goToSensorList('pollen','deploy', 2)">
                            <div class="content-cards-hover p-3 card-body">
                                <div
                                    class="d-flex flex-row-reverse justify-content-between align-items-center align-content-center">
                                    <div
                                        class="bg-orange rounded shadow icon-square d-flex justify-content-center align-items-center">
                                        <b-icon icon="truck" class="clr-white" scale="1.4"></b-icon>
                                    </div>
                                    <div>
                                        <p class="mb-0 clr-light-grey font-weight-bold">
                                            En attente d'installation
                                        </p>
                                        <h5 class="mb-0 font-weight-bolder clr-dark-grey">
                                            {{
                                                getAnalyticsByTypeAndStatus({
                                                    type: 'pollen',
                                                    status: 'deploy',
                                                    statusStep: 2
                                                })
                                            }}
                                            <span class="font-weight-bolder text-success"> </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-col>

            <!-- SENSORS ACTION -->
            <b-col cols="12" md="6">
                <b-row align-v="stretch">
                    <!-- ADD SENSOR -->
                    <b-col cols="12" md="6" class="mb-2">
                        <div
                            class="basic-card orange add-cursor content-cards-hover h-100 p-0"
                            @click="goTo('sensors/new')">
                            <b-row
                                align-v="center"
                                class="card-body justify-content-around h-100 p-3">
                                <b-col cols="2" class="text-right">
                                    <b-icon icon="plus-circle" class="clr-white" scale="1.4"/>
                                </b-col>
                                <b-col>
                                    <p class="font-weight-bolder clr-white mb-0">
                                        AJOUTER CAPTEUR
                                    </p>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                    <!-- SHIPPING AND RETURNS -->
                    <b-col cols="12" md="6" class="mb-2">
                        <div class="basic-card orange add-cursor content-cards-hover h-100 p-0">
                            <b-row
                                align-v="center"
                                class="card-body justify-content-around h-100 p-3"
                                v-b-modal.shipping-modal>
                                <b-col cols="2" class="text-right">
                                    <b-icon icon="box" class="clr-white" scale="1.4"/>
                                </b-col>
                                <b-col>
                                    <p class="font-weight-bolder clr-white mb-0">
                                        ENVOIS ET RETOURS
                                    </p>
                                </b-col>
                            </b-row>
                        </div>
                        <b-modal id="shipping-modal" hide-footer hide-header centered>
                            <div class="d-block text-center">
                                <h4 class="clr-darker-grey my-3">
                                    ENVOIS ET RETOURS
                                </h4>
                            </div>
                            <b-button class="mt-2 bg-white border-orange clr-orange"
                                      @click="goTo('Deliveries', 'shipping')" block>
                                <b-icon icon="arrow-up-circle"></b-icon>
                                Gestion Envois
                            </b-button>
                            <b-button class="mt-2 bg-white border-orange clr-orange"
                                      @click="goTo('Deliveries', 'returning')" block>
                                <b-icon icon="arrow-down-circle"></b-icon>
                                Gestion Retours
                            </b-button>
                            <b-button class="mt-2 bg-white border-orange clr-orange" @click="goTo('Deliveries')" block>
                                <b-icon icon="file-earmark-medical"></b-icon>
                                Suivi des retours
                            </b-button>
                        </b-modal>
                    </b-col>
                    <!-- TEST SENSOR -->
                    <b-col cols="12" md="6" class="mb-md-0 mb-2">
                        <div class="basic-card orange add-cursor content-cards-hover h-100 p-0">
                            <b-row
                                align-v="center"
                                class="card-body justify-content-around h-100 p-3"
                                @click="goTo('/sensors/testing')">
                                <b-col cols="2" class="text-right">
                                    <b-icon icon="ui-checks" class="clr-white" scale="1.4"/>
                                </b-col>
                                <b-col>
                                    <p class="font-weight-bolder clr-white mb-0">
                                        TESTER UN CAPTEUR
                                    </p>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                    <!-- REPLACE SENSOR -->
                    <b-col cols="12" md="6" class="mb-md-0 mb-2">
                        <div class="basic-card orange add-cursor content-cards-hover h-100 p-0">
                            <b-row
                                @click="goTo('Deliveries', 'returning')"
                                align-v="center"
                                class="card-body justify-content-around h-100 p-3">
                                <b-col cols="2" class="text-right">
                                    <b-icon icon="wrench" class="clr-white" scale="1.4"/>
                                </b-col>
                                <b-col>
                                    <p class="font-weight-bolder clr-white mb-0">
                                        REMPLACER UN CAPTEUR
                                    </p>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-col>
</template>

<script>
import Vuex from 'vuex'

export default {
    name: "DashboardContent",
    data() {
        return {}
    },
    methods: {
        ...Vuex.mapActions('analytics', {
            setData: 'setData'
        }),
        ...Vuex.mapActions('sensor', {
            setChosenType: 'setChosenType',
            setChosenStatus: 'setChosenStatus',
            setChosenStatusStep: 'setChosenStatusStep',
        }),
        colorLevel(value, total) {
            let percentage = parseInt(value) / parseInt(total)

            if (percentage <= 0.33) {
                return 'clr-red'
            } else if (percentage <= 0.67) {
                return 'clr-orange'
            } else {
                return 'clr-green'
            }
        },
        goTo(route, query = null) {
            if (query) {
                this.$router.push({
                    name: route,
                    query: {deliveryType: query}
                })
            } else {
                this.$router.push(route)
            }
        },
        goToSensorList(type, status, step) {
            this.goTo('sensors')
            this.setChosenType(type)
            this.setChosenStatus(status)
            this.setChosenStatusStep(step)
        }
    },
    computed: {
        ...Vuex.mapGetters('analytics', {
            getSensorAnalytics: 'getSensorAnalytics',
            getAnalyticsByTypeAndStatus: 'getAnalyticsByTypeAndStatus',
        }),
    },
    async created() {
        await this.setData({type: 'sensor'})
    },
}
</script>

<style lang="scss" scoped>
.content-cards-hover {
    min-height: 80px;
    transition: transform 330ms ease-in-out;
    transform: scale(1);

    &:hover {
        box-shadow: 0 0.5rem 2rem rgb(0 0 0 / 20%) !important;
        transform: scale(1.01);
        transition: transform 200ms ease-in-out;
    }
}
</style>
