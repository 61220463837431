export default {
    formatSensorPayload,
    mailToGenerator
}

function formatSensorPayload(sensor){
    let payload = {
        id: sensor.id,
        serialNumber: sensor.serialNumber,
        sensorAddress: sensor.sensorAddress,
        sensorName: sensor.sensorName,
        checked: sensor.checked,
        sensorParameters: sensor.sensorParameters,
        replaced: false
    };

    payload.dataReference = {
        referenceId: sensor.dataReference.referenceId
    };

    payload.account = {
        id: sensor.owningAccount.id,
        name: sensor.owningAccount.name,
    };

    return payload;
}

function mailToGenerator(email, subject, body) {
    // Encode the email, subject and body to be URL-safe
    const encodedEmail = encodeURIComponent(email);
    const encodedSubject = encodeURIComponent(subject);
    const encodedBody = encodeURIComponent(body);

    // Construct the mailto link
    return `mailto:${encodedEmail}?subject=${encodedSubject}&body=${encodedBody}`;
}