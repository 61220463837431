<template>
    <b-row class="justify-content-center">
        <DashboardContent></DashboardContent>
        <DashboardSensors></DashboardSensors>
    </b-row>
</template>

<script>
import DashboardContent from "@/components/dashboard/DashboardContent";
import DashboardSensors from "@/components/dashboard/DashboardSensors";

	export default {
		components: {
            DashboardSensors,
			DashboardContent,
		},
		data () {
				return {}
		},
	}
</script>

<style lang="scss">

.icon-square {
  width: 2.5em;
  height: 2.5em;
}
</style>
