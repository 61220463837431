<template>
    <div class="container-fluid full-view-height p-0 p-md-2">
        <vue-page-transition name="fade-in-right">
            <router-view></router-view>
        </vue-page-transition>
    </div>
</template>

<script>
    export default {
        name: "User",
        components:{
        },
    }
</script>

<style scoped>

</style>