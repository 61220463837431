<template>
    <div class="container-fluid full-view-height">
        <ConnexionForm></ConnexionForm>
    </div>
</template>

<script>
import ConnexionForm from "../components/login/ConnexionForm";

export default {
    name: "Login",
    components: {ConnexionForm}
}
</script>

<style scoped>

</style>