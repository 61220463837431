<template>
    <b-row class="min-vh-100 justify-content-center bg-lighter-grey">
        <b-col cols="12" v-if="authStatus" id="auth-status" class="mt-3">
            <p class="text-center clr-red">{{ authStatus.message }}</p>
        </b-col>
        <div>
            <label class="language-selector__container text-right">
                <select class="js_language_selector" v-model="$i18n.locale" style="text-transform: uppercase">
                    <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang"> {{ lang }}</option>
                </select>
            </label>
        </div>
        <b-row class="align-content-around align-items-center text-center login-card shadow" align-v="end">
            <b-col cols="12">
                <img src="@/assets/logo.png" style="max-height: 90px; margin-bottom: -20px"/>
            </b-col>
            <b-col cols="12" class="mb-3">
                <p class="container-title">
                    Administration
                </p>
            </b-col>
            <b-col cols="12">
                <b-row class="justify-content-center text-center mb-5">
                    <b-col cols="12" md="10" class="font-weight-bolder">
                        <transition name="fade" mode="out-in">
                            <b-form @submit.prevent="resetPassword()" v-if="showForgotPassword" key="askForReset">
                                <b-form-group>
                                    <b-form-input
                                        id="input-1"
                                        v-model="email"
                                        type="email"
                                        autocomplete="username"
                                        required
                                        :placeholder="capitalize($t('form.placeholder.email'))"
                                        class="login-input"
                                    ></b-form-input>
                                </b-form-group>
                                <b-button type="submit" variant="primary">
                                    {{ capitalize($t('form.button.forgotMyPassword')) }}
                                </b-button>
                            </b-form>
                            <b-form @submit.prevent="connexion()" v-else key="login">
                                <b-form-group>
                                    <b-form-input
                                        id="input-1"
                                        v-model="email"
                                        type="email"
                                        required
                                        autocomplete="username"
                                        :placeholder="capitalize($t('form.placeholder.email'))"
                                        class="login-input"
                                    ></b-form-input>
                                </b-form-group>
                                <b-form-group class="mb-5">
                                    <b-form-input
                                        id="input-2"
                                        v-model="password"
                                        type="password"
                                        required
                                        autocomplete="current-password"
                                        :placeholder="capitalize($t('form.placeholder.password'))"
                                        class="login-input"
                                    ></b-form-input>
                                </b-form-group>

                                <b-button type="submit" variant="primary" v-if="!connexionIsLoading">
                                    {{ capitalize($t('form.button.connect')) }}
                                </b-button>
                                <b-button type="submit" variant="primary" v-else>
                                    {{ capitalize($t('form.title.connection')) }}
                                    <b-spinner small></b-spinner>
                                </b-button>
                            </b-form>
                        </transition>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" class="add-cursor">
                <p @click="showForgotPassword = !showForgotPassword"
                   class="clr-light-grey"
                   v-if="!showForgotPassword">
                    {{ $t('form.button.forgotPassword') }}
                </p>
                <p @click="showForgotPassword = !showForgotPassword"
                   class="clr-light-grey"
                   v-else>
                    {{ $t('form.button.cancel') }}
                </p>
            </b-col>
        </b-row>
        <img class="site-logo" src="@/assets/images/icon/Logo_LifyAir.png" style="max-height: 90px;"/>
    </b-row>

</template>

<script>
import Vuex from 'vuex'

export default {
    name: "ConnexionForm",
    data() {
        return {
            email: '',
            password: '',
            alert: false,
            passwordType: 'password',
            langs: ['fr', 'en'],
            showForgotPassword: false,
        }
    },

    methods: {
        ...Vuex.mapActions('login', {
            logMeIn: 'logMeIn',
            askForPasswordReset: 'askForPasswordReset',
            logout: 'logout'
        }),
        async connexion() {
            this.alert = false;
            await this.logMeIn([this.email, this.password]);
            if (this.authStatus.message && (this.authStatus.message.message === 'User doesn\'t exist' || this.authStatus.message.message === 'Invalid credentials')) {
                this.alert = true
            }
        },
        async resetPassword() {
            let query = this.generateQueryString()
            await this.askForPasswordReset(query);
        },
        generateQueryString() {
            let data = []
            let fields = new FormData();
            fields.set('email', this.email)
            data.push(fields)
            return fields
        },
        capitalize(value) {
            return this.$options.filters.capitalize(value)
        }

    },
    computed: {
        ...Vuex.mapGetters('login', {
            authStatus: 'authStatus',
            connexionIsLoading: 'getConnexionIsLoading',
            resetMailSent: 'resetMailSent'
        }),
    },
    watch: {
        resetMailSent: function (val) {
            if (val) {
                this.showForgotPassword = true
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.login-card {
    padding: 1vh 0;
    max-width: 500px;
    background-color: white;
    border-radius: 10px 10px 0 0;
}

.login-input {
    background-color: white;
    border: none;
    color: $light-grey;
    box-shadow: none !important;
    border-bottom: 1px solid $light-grey;
    border-radius: 0;

    &:active {
        color: $orange;
        border-bottom: 1px solid $orange;
        box-shadow: none !important;
    }

    &:focus {
        color: $orange;
        border-bottom: 1px solid $orange;
        box-shadow: none !important;
    }
}

.language-selector__container {
    position: fixed;
    top: 20px;
    right: 20px;

    select {
        background-color: white;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        border-radius: 5px;
        box-sizing: border-box;
        color: #3E3D40;
        height: 40px;
        min-width: 80px;
        padding: 0 20px 0 16px;
        outline: none;
    }

    label {
        position: relative
    }

    label:after {
        content: '';
        left: 54px;
        top: 17px;
        padding: 0 0 2px;
        position: absolute;
        pointer-events: none;
        width: 0;
        height: 0;
        border-left: 3.5px solid transparent;
        border-right: 3.5px solid transparent;
        border-top: 5px solid $orange;
        z-index: 100;
    }

    label:before {
        content: '';
        left: 56px;
        top: 10px;
        width: 20px;
        height: 20px;
        background: white;
        position: absolute;
        pointer-events: none;
        display: block;
        z-index: 1;
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.site-logo {
    position: fixed;
    bottom: 0;
    right: 0;
}
</style>
