<template>
    <div>
        <article>
            <h1>{{ $t(`error.${type}.title`) }}</h1>
            <div>
                <p v-html="$t(`error.${type}.message`)"></p>
                <p v-html="$t(`error.${type}.signature`)"></p>
            </div>
        </article>
        <img src="@/assets/images/icon/Logo_LifyAir.png" alt="logo lifyair" class="lifyair-logo"/>
    </div>
</template>

<script>
export default {
    name: "Error",
    props: {
        type: {
            type: String,
            default: 'notFound'
        }
    }
}
</script>

<style scoped>
h1 {
    font-size: 3rem;
    margin-top: 30Vh;
}

body {
    text-align: center;
    padding: 20px;
    font: 2rem Helvetica, sans-serif;
    color: #333;
}

a {
    color: #dc8100;
    text-decoration: none;
}

a:hover {
    color: #333;
    text-decoration: none;
}

article {
    display: block;
    text-align: left;
    width: 750px;
    margin: 0 auto;
}

@media only screen and (max-width: 600px) {
    body {
        padding-top: 150px;
    }

    article {
        width: auto;
        text-align: center;
    }

    h1 {
        font-size: 2rem;
    }
}

.lifyair-logo {
    width: 200px;
    position: absolute;
    bottom: 5px;
    right: 20px;
}
</style>