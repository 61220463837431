<template>
  <div class="container-fluid">
      <div class="row justify-content-center align-content-center align-items-sm-center mb-5">
        <div class="col-xl-3 clr-dark-grey shadow mr-1 text-center add-cursor" @click="$router.push('/parameters/report-types')">
          <p class="m-2">
            <b-icon :icon="'gear'"></b-icon>
            Type de Rapport de données
          </p>
        </div>
        <div class="col-xl-3 shadow ml-1 text-center add-cursor" @click="$swal.fire('Bientôt Disponible')">
          <p class="m-2">
            <b-icon :icon="'gear'"></b-icon>
            Zones de Risque Pollinique
          </p>
        </div>
      </div>
      <vue-page-transition name="fade-in-right">
        <router-view></router-view>
      </vue-page-transition>
  </div>
</template>

<script>
export default {
name: "Parameter"
}
</script>

<style scoped>

</style>