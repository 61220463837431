<template>
    <div class="container-fluid">
        <div class="row justify-content-center">
<!--            <div class="col-xl-3 col-md-6 col-sm-12">-->
<!--                <b-card-->
<!--                        title="DynamoDB"-->
<!--                        style="max-width: 20rem;"-->
<!--                        class="mb-2"-->
<!--                >-->
<!--                    <b-card-text>-->
<!--                        Pré-visualisation de données de la base NOSQL DynamoDB par capteur-->
<!--                    </b-card-text>-->
<!--                    <b-button href="#" variant="primary" @click="$router.push('/infrastructures/dynamodb')">Afficher</b-button>-->
<!--                </b-card>-->
<!--            </div>-->
            <div class="col-xl-3 col-md-6 col-sm-12">
                <b-card
                        title="AWS ElasticBeanstalk"
                        style="max-width: 20rem;"
                        class="mb-2"
                >
                    <b-card-text>
                        Informations de suivies des API et Environnements associés
                    </b-card-text>

                    <b-button href="#" variant="primary" @click="$router.push('/infrastructures/EBS')">Afficher</b-button>
                </b-card>
            </div>
            <div class="col-xl-3 d-none col-md-6 col-sm-12">
                <b-card
                        title="AWS S3"
                        style="max-width: 20rem;"
                        class="mb-2"
                >
                    <b-card-text>
                        Informations de suivies des applications Front et Environnements associés
                    </b-card-text>
                    <b-button href="#" variant="primary" @click="$router.push('/infrastructures/S3')">Afficher</b-button>
                </b-card>
            </div>
            <div class="col-xl-3 col-md-6 col-sm-12">
                <b-card
                        title="GitLab"
                        style="max-width: 20rem;"
                        class="mb-2"
                >
                    <b-card-text>
                        Informations de suivies des builds d'applications
                    </b-card-text>
                    <b-button href="#" variant="primary" @click="$router.push('/infrastructures/gitlab')">Afficher</b-button>
                </b-card>
            </div>
        </div>
        <vue-page-transition name="fade-in-right">
            <router-view></router-view>
        </vue-page-transition>
    </div>
</template>

<script>
    export default {
        name: "Infrastructure",
        components: {}
    }
</script>

<style scoped>
</style>