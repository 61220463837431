<template>
    <vue-page-transition name="fade-in-right">
        <router-view></router-view>
    </vue-page-transition>
</template>

<script>
export default {
    name: "DataAccess",
    components: {},
}
</script>

<style scoped>

</style>